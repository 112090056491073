import { CustomFormValidators } from "../../shared/validators/custom-form-validators";

export class GetYoutubeVideoIdFromUrlInteractor {
  public execute(url: string): string | undefined {
    const match = url.match(CustomFormValidators.YOUTUBE_URL_REGEX);
    if (!match || match[7].length !== 11) {
      return undefined;
    }
    return match[7];
  }
}
