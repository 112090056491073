import { HttpClient } from "@angular/common/http";
import { NgModule } from "@angular/core";
import {
  AngularApiRequestService,
  AuthService,
} from "../../../harmony/angular";
import { ApiRequestService } from "../../../harmony/core";
import { AuthTokenService } from "../../shared/services/api/auth-token.service";
import { AuthNetworkService } from "../../shared/services/api/auth.network.service";
import { DownloadFileInteractor } from "./download-file.interactor";
import { GetYoutubeVideoIdFromUrlInteractor } from "./get-youtube-video-id-from-url.interactor";
import { CreateZipFileInteractor } from "./create-zip-file.interactor";

@NgModule({
  providers: [
    {
      provide: AuthNetworkService,
      deps: [AuthTokenService],
      useFactory: (authTokenService: AuthTokenService): AuthService =>
        new AuthNetworkService(authTokenService),
    },
    {
      provide: ApiRequestService,
      deps: [HttpClient, "MONOLITH_HARMONY_API_URL", AuthNetworkService],
      useFactory: (
        httpClient: HttpClient,
        API_URL: string,
        authNetworkService: AuthNetworkService,
      ): ApiRequestService =>
        new AngularApiRequestService(API_URL, httpClient, authNetworkService),
    },
    {
      provide: GetYoutubeVideoIdFromUrlInteractor,
      useFactory: () => new GetYoutubeVideoIdFromUrlInteractor(),
    },
    {
      provide: CreateZipFileInteractor,
      useFactory: () => new CreateZipFileInteractor(),
    },
    {
      provide: DownloadFileInteractor,
      useFactory: () => new DownloadFileInteractor(),
    },
  ],
})
export class SharedProviderModule {}
